import type { FilterConfig } from '~/components/Filters/configs'
import type { FilterGroup, FilterProperty } from '~/components/Filters/schema'

export default (filterConfig: FilterConfig, filter?: FilterGroup | FilterProperty, searchText?: string) => {
  if (!filter && !searchText) {
    return undefined
  }

  const { $i18n } = useNuxtApp()
  function translateFilterTerm(filter: FilterProperty): string {
    const pathLabel = filterConfig[filter.path].pathLabel
    const fieldType = filterConfig[filter.path].methodType
    const methodTranslation = $i18n.t(`filters.${fieldType}.${filter.method}`)

    let valueTranslation
    if (Array.isArray(filter.value)) {
      if (filter.method === 'dateRange') {
        valueTranslation = filter.value.map(date => $i18n.d(new Date(date), 'short')).join(' - ')
      } else {
        valueTranslation = filter.value.join(', ')
      }
    } else if (filter.method === 'lteIsoDate' || filter.method === 'gteIsoDate') {
      const date = new Date(filter.value)
      valueTranslation = $i18n.d(date, 'short')
    } else {
      valueTranslation = filter.value
    }

    return `"${pathLabel}" ${methodTranslation} "${valueTranslation}"`
  }

  function translateFilters(filter: FilterGroup | FilterProperty): string {
    if (filter.type === 'group') {
      const subFilters = filter.filters.map((subFilter: FilterGroup | FilterProperty) => translateFilters(subFilter))
      const conjunction = $i18n.t(`filters.conjunction.${filter.conjunction}`)
      return subFilters.join(` ${conjunction.toUpperCase()} `)
    } else if (filter.type === 'filter') {
      return translateFilterTerm(filter)
    }
    return ''
  }
  const fullText = `${filter ? `${translateFilters(filter)} ` : ''}${searchText ? `${$i18n.t('filters.searchText')} "${searchText}"` : ''}`
  return fullText
}
